import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">
            
<path fillRule="evenodd" clipRule="evenodd" d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z"/>
<path d="M120.72 16.6689L122.561 17.256L122.648 25.6091L121.188 25.149L121.18 23.0865L118.291 22.1663L117.101 23.848L115.775 23.4276L120.72 16.6689ZM118.95 21.2382L121.18 21.9521L121.188 18.0889L118.95 21.2382Z"/>
<path d="M127.402 19.001L129.735 20.0243C131.362 20.7382 132.116 21.9202 131.45 23.4433L131.434 23.483C130.759 25.014 129.354 25.2123 127.799 24.5301L126.783 24.0858L125.577 26.8464L124.228 26.2515L127.402 19.001ZM128.196 23.5464C129.092 23.9351 129.759 23.713 130.108 22.9039L130.124 22.8642C130.505 21.9995 130.14 21.4442 129.275 21.0635L128.291 20.6351L127.204 23.1101L128.196 23.5464Z"/>
<path d="M134.711 22.3566L136.941 23.602C138.488 24.4667 139.123 25.72 138.314 27.1717L138.29 27.2114C137.472 28.671 136.06 28.7345 134.576 27.9095L133.607 27.37L132.139 30.0037L130.854 29.2818L134.711 22.3566ZM135.068 26.9575C135.917 27.4335 136.607 27.2669 137.036 26.5054L137.06 26.4657C137.52 25.6407 137.21 25.0537 136.393 24.5936L135.457 24.07L134.139 26.434L135.068 26.9575Z"/>
<path d="M141.632 26.3944L142.846 27.2273L139.028 32.7961L141.814 34.7079L141.147 35.6757L137.14 32.931L141.632 26.3944Z"/>
<path d="M147.647 30.6858L151.465 33.843L150.719 34.7394L148.036 32.5182L146.647 34.192L148.798 35.9769L148.092 36.8336L145.941 35.0488L144.465 36.8336L147.29 39.1738L146.544 40.0702L142.584 36.794L147.647 30.6858Z"/>
<path d="M156.187 38.3489L157.909 40.2289C159.107 41.5378 159.314 42.926 158.084 44.0525L158.052 44.0842C156.814 45.2107 155.449 44.822 154.306 43.5686L153.56 42.7515L151.33 44.7902L150.338 43.7034L156.187 38.3489ZM155.068 42.8309C155.727 43.5527 156.433 43.6162 157.076 43.0212L157.108 42.9895C157.806 42.3549 157.703 41.6965 157.06 41.0063L156.33 40.2131L154.33 42.0376L155.068 42.8309Z"/>
<path d="M161.52 44.3936L163.115 46.5274C164.155 47.9236 164.321 49.3277 163.02 50.2955L162.988 50.3193C161.996 51.065 161.02 50.819 160.226 50.1765L158.814 54.008L157.861 52.7308L159.187 49.1532L158.623 48.3996L156.052 50.3193L155.171 49.1373L161.52 44.3936ZM160.171 48.7248C160.758 49.5181 161.409 49.6847 162.083 49.177L162.115 49.1532C162.845 48.6058 162.758 47.9712 162.187 47.2176L161.48 46.2657L159.456 47.7808L160.171 48.7248Z"/>
<path d="M162.845 52.9688L162.925 52.9212C164.925 51.7551 167.25 52.3262 168.44 54.3491C169.631 56.3878 169.004 58.6407 166.996 59.8147L166.917 59.8623C164.917 61.0284 162.607 60.5445 161.393 58.4662C160.171 56.364 160.869 54.119 162.845 52.9688ZM166.131 58.5455L166.21 58.4979C167.615 57.6729 168.202 56.2847 167.425 54.944C166.639 53.6034 165.187 53.3892 163.718 54.246L163.639 54.2935C162.147 55.1661 161.71 56.6416 162.433 57.8871C163.187 59.1801 164.647 59.4181 166.131 58.5455Z"/>
<path d="M171.568 60.3939L172.528 62.5992C173.631 65.1297 172.718 66.986 170.599 67.9062L170.504 67.9459C168.385 68.866 166.346 68.287 165.242 65.7326L164.29 63.5432L171.568 60.3939ZM166.298 65.2329C167.004 66.8591 168.282 67.2319 169.885 66.5418L169.964 66.51C171.528 65.8278 172.171 64.7093 171.425 62.9958L171.083 62.2105L165.949 64.4396L166.298 65.2329Z"/>
<path d="M170.384 70.3336L175.082 68.8978L175.519 70.3178L170.924 71.7219C169.535 72.1502 169.099 72.769 169.44 73.8954C169.773 74.9901 170.432 75.3868 172.003 74.9108L176.503 73.5385L176.94 74.9584L172.329 76.3704C170.345 76.9733 168.924 76.2435 168.313 74.2365C167.726 72.3327 168.432 70.9365 170.384 70.3336Z"/>
<path d="M173.655 78.3138L173.742 78.298C176.091 77.8696 178.02 79.1785 178.424 81.3521C178.758 83.1369 178.115 84.6917 176.313 85.1994L176.043 83.7239C177.051 83.367 177.48 82.6927 177.274 81.5663C177.02 80.1939 175.79 79.4958 174.044 79.8131L173.956 79.829C172.202 80.1542 171.321 81.1855 171.583 82.6213C171.782 83.7001 172.472 84.3665 173.599 84.311L173.861 85.7309C171.94 85.8499 170.75 84.6917 170.409 82.8355C169.948 80.4081 171.258 78.7501 173.655 78.3138Z"/>
<path d="M178.154 88.9516L177.979 86.7543L179.138 86.6591L179.606 92.5372L178.448 92.6324L178.273 90.435L171.527 90.9744L171.408 89.491L178.154 88.9516Z"/>
<path d="M179.671 98.7565L179.512 101.16C179.337 103.913 177.679 105.15 175.377 105L175.274 104.992C172.972 104.841 171.417 103.397 171.599 100.621L171.75 98.2408L179.671 98.7565ZM172.766 100.652C172.655 102.421 173.615 103.342 175.361 103.453L175.449 103.461C177.155 103.572 178.234 102.866 178.353 101.001L178.409 100.153L172.822 99.7957L172.766 100.652Z"/>
<path d="M178.877 107.95L178.051 112.837L176.9 112.647L177.48 109.212L175.337 108.847L174.877 111.599L173.782 111.417L174.242 108.664L171.956 108.276L171.345 111.893L170.194 111.703L171.051 106.634L178.877 107.95Z"/>
<path d="M172.297 113.702L171.924 115.074C171.131 114.963 170.401 115.098 170.051 116.391C169.821 117.24 170.131 117.961 170.837 118.152C171.543 118.342 171.908 118.065 172.448 116.875C173.186 115.114 173.98 114.392 175.416 114.78C176.67 115.122 177.321 116.446 176.892 118.041C176.448 119.691 175.432 120.468 173.924 120.222L174.281 118.921C175.123 119.001 175.591 118.628 175.829 117.755C176.059 116.89 175.765 116.327 175.178 116.169C174.559 116.002 174.178 116.177 173.623 117.446C172.892 119.175 172.123 119.968 170.591 119.556C169.266 119.199 168.528 117.795 168.988 116.105C169.551 114.011 170.821 113.416 172.297 113.702Z"/>
<path d="M175.099 123.554L174.599 124.95L167.123 122.293L167.623 120.897L175.099 123.554Z"/>
<path d="M170.052 125.847L170.131 125.886C172.282 126.87 173.131 129.059 172.163 131.169C171.456 132.716 170.123 133.763 168.313 133.16L168.925 131.82C169.956 132.098 170.663 131.637 171.091 130.701C171.734 129.289 171.059 127.996 169.48 127.266L169.401 127.227C167.861 126.521 166.472 126.751 165.782 128.266C165.163 129.615 165.726 130.543 166.71 131.042L167.544 129.226L168.552 129.686L167.115 132.819L166.448 132.518C164.449 131.606 163.814 129.805 164.742 127.766C165.806 125.466 167.925 124.879 170.052 125.847Z"/>
<path d="M169.202 136.873L168.281 138.38L161.647 138.245L166.543 141.228L165.845 142.37L159.075 138.245L159.869 136.937L166.916 137.056L161.726 133.89L162.424 132.748L169.202 136.873Z"/>
<path d="M164.027 144.941L161.019 148.883L160.091 148.177L162.202 145.409L160.471 144.092L158.781 146.313L157.9 145.639L159.591 143.418L157.749 142.013L155.527 144.933L154.599 144.227L157.718 140.141L164.027 144.941Z"/>
<path d="M159.219 151.041L157.401 152.992C156.211 154.269 154.862 154.674 153.679 153.571L153.648 153.54C152.735 152.691 152.806 151.691 153.29 150.795L149.259 150.097L150.346 148.931L154.108 149.597L154.751 148.907L152.402 146.725L153.409 145.647L159.219 151.041ZM154.719 150.493C154.044 151.215 153.997 151.882 154.616 152.461L154.647 152.493C155.314 153.111 155.925 152.913 156.568 152.215L157.378 151.342L155.528 149.621L154.719 150.493Z"/>
<path d="M148.878 160.981L147.37 162.178L140.386 157.601L141.584 156.649L143.298 157.792L145.671 155.912L144.933 153.992L146.02 153.127L148.878 160.981ZM146.068 156.967L144.235 158.418L147.441 160.576L146.068 156.967Z"/>
<path d="M143.228 165.232L141.077 166.605C139.585 167.557 138.172 167.525 137.283 166.121L137.26 166.081C136.355 164.669 136.974 163.392 138.41 162.48L139.347 161.885L137.72 159.338L138.966 158.545L143.228 165.232ZM139.005 163.36C138.18 163.884 137.998 164.566 138.474 165.304L138.498 165.343C139.005 166.137 139.672 166.145 140.466 165.645L141.37 165.066L139.91 162.789L139.005 163.36Z"/>
<path d="M136.37 169.437L134.1 170.595C132.521 171.404 131.124 171.23 130.362 169.746L130.338 169.707C129.577 168.215 130.315 167.001 131.83 166.232L132.823 165.724L131.45 163.035L132.767 162.361L136.37 169.437ZM132.346 167.176C131.473 167.62 131.227 168.279 131.624 169.064L131.648 169.104C132.076 169.945 132.735 170.016 133.576 169.588L134.537 169.096L133.307 166.684L132.346 167.176Z"/>
<path d="M129.132 172.951L127.759 173.498L125.259 167.224L122.116 168.477L121.68 167.382L126.195 165.582L129.132 172.951Z"/>
<path d="M122.267 175.513L120.846 175.965L118.449 168.413L119.87 167.961L122.267 175.513Z"/>
<path d="M117.355 172.88L117.37 172.967C117.894 175.291 116.656 177.274 114.505 177.758C112.736 178.155 111.156 177.584 110.577 175.807L112.037 175.482C112.434 176.473 113.125 176.87 114.236 176.624C115.601 176.314 116.244 175.061 115.855 173.332L115.839 173.244C115.45 171.507 114.386 170.666 112.958 170.984C111.887 171.222 111.252 171.936 111.347 173.062L109.942 173.379C109.752 171.468 110.855 170.23 112.696 169.818C115.117 169.262 116.815 170.5 117.355 172.88Z"/>
<path d="M106.632 179.004L104.712 179.218L101.228 171.634L102.751 171.467L103.6 173.348L106.608 173.006L107.005 170.992L108.386 170.841L106.632 179.004ZM106.386 174.109L104.061 174.371L105.632 177.901L106.386 174.109Z"/>
<path d="M98.7842 178.432L100.991 178.385L101.014 179.551L95.1097 179.67L95.0859 178.504L97.2922 178.456L97.1494 171.69L98.6334 171.658L98.7842 178.432Z"/>
<path d="M92.6882 179.559L91.2041 179.48L91.6089 171.563L93.0929 171.642L92.6882 179.559Z"/>
<path d="M89.0385 175.386L89.0227 175.474C88.6973 177.766 86.8085 179.234 84.4752 178.908C82.134 178.575 80.7531 176.687 81.0864 174.387L81.1023 174.3C81.4277 172.007 83.2451 170.508 85.626 170.841C88.0306 171.182 89.356 173.126 89.0385 175.386ZM82.6181 174.498L82.6023 174.585C82.3721 176.195 83.1022 177.52 84.6419 177.742C86.1815 177.964 87.2529 176.957 87.491 175.267L87.5068 175.18C87.7529 173.467 86.864 172.213 85.4355 172.007C83.9593 171.801 82.8642 172.792 82.6181 174.498Z"/>
<path d="M77.7845 177.631L76.0782 177.171L74.3402 170.77L72.8561 176.307L71.5625 175.958L73.618 168.295L75.0941 168.691L76.975 175.482L78.5464 169.611L79.84 169.96L77.7845 177.631Z"/>
<path d="M67.3006 169.58C66.999 170.294 66.1895 170.651 65.4435 170.341C64.6896 170.024 64.3801 169.199 64.6816 168.485C64.9832 167.755 65.7848 167.406 66.5387 167.723C67.2847 168.033 67.6022 168.858 67.3006 169.58Z"/>
<path d="M61.4678 163.368L60.9043 164.376C60.7138 164.146 60.5154 163.932 60.1107 163.709C59.4837 163.36 58.8885 163.464 58.4203 164.312L55.7537 169.104L54.4521 168.382L57.1346 163.559C57.9679 162.059 59.2139 161.853 60.4916 162.559C61.071 162.877 61.3091 163.13 61.4678 163.368Z"/>
<path d="M54.6738 162.837L51.8961 166.89L50.666 166.05L53.3802 162.083C54.2056 160.885 54.1579 160.124 53.1818 159.465C52.2374 158.823 51.4755 158.91 50.547 160.267L47.8883 164.146L46.6582 163.305L49.3883 159.323C50.5628 157.609 52.1342 157.3 53.8643 158.49C55.5071 159.616 55.8325 161.155 54.6738 162.837Z"/>
<path d="M48.1666 157.577L47.0872 156.657C47.5317 155.983 47.7142 155.269 46.6984 154.404C46.0317 153.833 45.246 153.801 44.7699 154.365C44.2937 154.928 44.3969 155.372 45.2381 156.364C46.5158 157.784 46.8254 158.807 45.8651 159.941C45.0238 160.933 43.5477 160.949 42.2858 159.878C40.9843 158.767 40.7144 157.522 41.5795 156.261L42.6112 157.141C42.1827 157.871 42.3176 158.45 43.008 159.037C43.6905 159.616 44.3254 159.592 44.7222 159.132C45.1429 158.64 45.1429 158.228 44.2302 157.181C42.9763 155.777 42.5874 154.745 43.6191 153.54C44.508 152.493 46.0952 152.429 47.4285 153.563C49.0634 154.96 49.0554 156.372 48.1666 157.577Z"/>
<path d="M37.9134 154.103L39.4531 155.69L38.6198 156.499L34.5088 152.271L35.3421 151.461L36.8817 153.048L41.7387 148.336L42.7704 149.399L37.9134 154.103Z"/>
<path d="M32.9211 150.39L31.9688 149.248L38.0479 144.155L39.0003 145.29L32.9211 150.39Z"/>
<path d="M29.9845 146.86L28.9687 145.417L31.7385 139.388L27.0481 142.688L26.2783 141.593L32.7622 137.032L33.6432 138.285L30.7306 144.703L35.6986 141.204L36.4685 142.299L29.9845 146.86Z"/>
<path d="M22.7943 135.659L21.6118 133.272C20.842 131.709 20.9372 130.305 22.3896 129.591L22.4293 129.567C23.5403 129.02 24.453 129.432 25.1196 130.202L27.2069 126.688L27.9132 128.115L25.9609 131.392L26.3815 132.24L29.2544 130.82L29.9052 132.145L22.7943 135.659ZM24.9054 131.645C24.4689 130.757 23.8578 130.479 23.1038 130.852L23.0642 130.868C22.2467 131.273 22.2229 131.915 22.6356 132.756L23.1594 133.819L25.4292 132.7L24.9054 131.645Z"/>
<path d="M19.1041 127.869L18.5962 126.481L21.7627 125.331L20.5485 121.983L17.3819 123.134L16.874 121.738L24.3262 119.032L24.8341 120.429L21.6675 121.579L22.8818 124.926L26.0483 123.776L26.5562 125.164L19.1041 127.869Z"/>
<path d="M19.9535 118.168L19.8662 118.192C17.6123 118.715 15.5568 117.478 15.033 115.193C14.5013 112.893 15.7632 110.925 18.0329 110.402L18.1202 110.378C20.3741 109.854 22.4296 111.012 22.9693 113.353C23.5169 115.725 22.1756 117.652 19.9535 118.168ZM18.4694 111.869L18.3821 111.893C16.7949 112.258 15.8187 113.416 16.1679 114.931C16.5171 116.446 17.8345 117.081 19.5011 116.7L19.5884 116.684C21.2709 116.296 22.128 115.018 21.8026 113.614C21.4693 112.147 20.144 111.481 18.4694 111.869Z"/>
<path d="M13.6519 106.935L13.4297 105.015L21.0088 101.509L21.1834 103.032L19.3025 103.881L19.6517 106.888L21.6754 107.276L21.8342 108.657L13.6519 106.935ZM18.5486 106.673L18.2787 104.349L14.755 105.928L18.5486 106.673Z"/>
<path d="M13.0407 99.8987L13.0011 97.4951C12.9534 94.7345 14.5089 93.3701 16.8184 93.3305H16.9216C19.231 93.2908 20.8897 94.6076 20.9373 97.392L20.977 99.7718L13.0407 99.8987ZM19.7707 97.4475C19.7389 95.6706 18.7072 94.8377 16.9533 94.8615H16.866C15.1597 94.8932 14.1359 95.6865 14.1677 97.5427L14.1836 98.3995L19.7865 98.3043L19.7707 97.4475Z"/>
<path d="M13.1523 90.6412L13.6206 85.707L14.7793 85.8181L14.446 89.2847L16.6125 89.4909L16.8744 86.7066L17.9776 86.8097L17.7157 89.5941L20.0251 89.8162L20.3743 86.1592L21.533 86.2703L21.041 91.3948L13.1523 90.6412Z"/>
<path d="M19.3024 84.4378L19.5723 83.0417C20.3738 83.0972 21.0881 82.9068 21.35 81.59C21.5167 80.7332 21.1595 80.0272 20.4373 79.8844C19.7151 79.7416 19.3739 80.051 18.9215 81.2727C18.3104 83.0813 17.5723 83.8587 16.1121 83.5731C14.8423 83.3272 14.0963 82.0501 14.4058 80.4318C14.7312 78.758 15.6915 77.9013 17.2073 78.0441L16.9454 79.3688C16.1041 79.353 15.6597 79.7496 15.4851 80.646C15.3105 81.5265 15.6518 82.0659 16.247 82.1849C16.8819 82.3039 17.247 82.1056 17.7073 80.7967C18.3104 79.0198 19.0167 78.171 20.5802 78.4724C21.9293 78.7342 22.7626 80.0828 22.4293 81.8042C22.0246 83.9301 20.7945 84.6123 19.3024 84.4378Z"/>
<path d="M21.0249 73.0625C20.2868 72.8245 19.8662 72.0392 20.1202 71.2697C20.3741 70.4923 21.1757 70.1194 21.9138 70.3654C22.6598 70.6113 23.0725 71.3807 22.8185 72.1582C22.5645 72.9276 21.7709 73.3084 21.0249 73.0625Z"/>
<path d="M19.8896 62.972L20.9372 60.7985C22.1356 58.3155 24.1355 57.792 26.2148 58.7915L26.3021 58.8312C28.3814 59.8307 29.2782 61.7504 28.064 64.2571L27.0243 66.4069L19.8896 62.972ZM27.0005 63.797C27.7703 62.2026 27.2227 60.9889 25.6434 60.2273L25.5641 60.1877C24.0244 59.4499 22.7626 59.6958 21.9531 61.3776L21.5801 62.147L26.6275 64.5824L27.0005 63.797Z"/>
<path d="M24.0723 54.7538L26.6674 50.5336L27.6594 51.1444L25.8341 54.1112L27.6833 55.2535L29.1515 52.8737L30.0959 53.4528L28.6277 55.8326L30.6038 57.0463L32.5244 53.9208L33.5164 54.5316L30.8181 58.9105L24.0723 54.7538Z"/>
<path d="M31.1358 51.0254L31.0644 50.9699C29.1755 49.5182 28.8502 47.2098 30.1914 45.4567C31.2945 44.0129 32.8738 43.4497 34.4611 44.4413L33.5484 45.6312C32.6119 45.1235 31.8183 45.2584 31.1279 46.1627C30.2787 47.2733 30.5882 48.6536 31.9929 49.7324L32.0643 49.7879C33.4849 50.8747 34.8341 50.8351 35.7309 49.6769C36.3975 48.8043 36.4293 47.8524 35.6356 47.0512L36.5086 45.9089C37.8816 47.2574 37.826 48.9153 36.6753 50.4067C35.1753 52.374 33.0722 52.5089 31.1358 51.0254Z"/>
<path d="M34.1514 40.8239L37.556 37.2224L38.4052 38.0236L36.0164 40.5541L37.5957 42.0455L39.5163 40.0147L40.3258 40.7763L38.3973 42.807L40.0797 44.4015L42.6035 41.7361L43.4526 42.5373L39.921 46.2816L34.1514 40.8239Z"/>
<path d="M39.6113 35.1758L41.1192 33.9065L46.1984 36.7068L44.2778 31.2491L45.7699 29.9957L50.8808 36.0642L49.7538 37.0162L45.8968 32.4311L47.9444 38.5313L47.5793 38.8407L41.8811 35.8104L45.7381 40.3955L44.7223 41.2522L39.6113 35.1758Z"/>
<path d="M48.3887 28.1554L50.7616 26.6006C52.1266 25.7042 53.2695 25.6962 54.0631 26.902L54.0869 26.9417C54.5154 27.6001 54.5631 28.314 53.9678 29.0359C54.9916 28.6393 55.7456 28.8534 56.3249 29.734L56.3487 29.7736C57.1582 31.0032 56.7535 32.1614 55.3011 33.1133L52.7536 34.7871L48.3887 28.1554ZM52.4441 29.4801C53.2615 28.9486 53.3806 28.4409 52.9361 27.7667L52.9123 27.727C52.4996 27.1003 51.96 27.0686 51.1743 27.5842L50.1743 28.2426L51.4282 30.1465L52.4441 29.4801ZM54.5631 32.3121C55.3884 31.7727 55.5233 31.1539 55.071 30.4638L55.0472 30.4241C54.5948 29.7419 53.9917 29.6467 53.0869 30.2417L51.9759 30.9715L53.3726 33.0895L54.5631 32.3121Z"/>
<path d="M55.5391 23.6497L59.9278 21.3413L60.4675 22.3726L57.3803 23.9908L58.3882 25.9185L60.8643 24.6175L61.3801 25.6012L58.904 26.9021L59.9834 28.9567L63.2372 27.2512L63.7769 28.2824L59.2215 30.6781L55.5391 23.6497Z"/>
<path d="M62.4834 20.1514L64.9516 19.1519C66.5626 18.5014 67.9594 18.6997 68.5705 20.199L68.5864 20.2386C69.0546 21.3889 68.5705 22.2694 67.7531 22.8723L71.1101 25.2124L69.626 25.8153L66.4991 23.6259L65.6261 23.9829L66.8325 26.9497L65.4674 27.505L62.4834 20.1514ZM66.3245 22.555C67.2372 22.1821 67.5626 21.5951 67.2451 20.8177L67.2293 20.7781C66.888 19.9372 66.2531 19.8579 65.3801 20.2148L64.277 20.6591L65.2293 22.9992L66.3245 22.555Z"/>
<path d="M75.1655 23.6417L77.3163 19.8578C77.8559 18.8979 78.1337 18.1126 77.9512 17.3828C77.7686 16.6609 77.2528 16.3119 76.5068 16.4943C75.7449 16.6847 75.364 17.2479 75.5068 18.3664L74.1894 18.6837C73.8719 16.9782 74.6418 15.7645 76.2687 15.3599C77.8639 14.9633 79.0384 15.6455 79.3718 17.002C79.6178 18.0015 79.2448 19.0169 78.5464 20.1275L77.2448 22.2217L80.5146 21.4126L80.7923 22.5232L75.229 23.9035L75.1655 23.6417Z"/>
<path d="M81.7602 18.4778L81.7047 18.0812C81.3634 15.7014 82.4745 14.0514 84.4506 13.7737C86.4347 13.4881 87.9426 14.7574 88.2759 17.1134L88.3314 17.51C88.6648 19.866 87.6489 21.5478 85.5934 21.8413C83.6014 22.1269 82.1015 20.8418 81.7602 18.4778ZM86.8791 17.7163L86.8236 17.3355C86.5855 15.6696 85.8077 14.7494 84.6332 14.916C83.4427 15.0826 82.9348 16.1218 83.1808 17.867L83.2364 18.2636C83.4903 20.0406 84.2998 20.8418 85.4744 20.6752C86.6807 20.5006 87.1251 19.5011 86.8791 17.7163Z"/>
<path d="M90.1172 20.897L92.9742 17.6128C93.6964 16.7878 94.1171 16.066 94.0853 15.3124C94.0536 14.5667 93.6171 14.1225 92.8473 14.1542C92.0616 14.1859 91.5775 14.6619 91.4981 15.7883L90.1489 15.8438C90.1807 14.1145 91.1806 13.0674 92.8473 13.0039C94.4901 12.9325 95.498 13.8369 95.5614 15.233C95.6011 16.2643 95.0297 17.1845 94.125 18.1364L92.4346 19.9292L95.7995 19.7864L95.8472 20.9287L90.1172 21.1667V20.897Z"/>
<path d="M97.6176 17.1607L97.6414 16.7641C97.7843 14.3605 99.1969 12.9643 101.189 13.0833C103.189 13.2023 104.411 14.7333 104.276 17.1131L104.252 17.5098C104.117 19.8896 102.784 21.3333 100.721 21.2144C98.6969 21.0954 97.4827 19.5406 97.6176 17.1607ZM102.776 17.4225L102.8 17.0338C102.895 15.3521 102.316 14.297 101.133 14.2256C99.935 14.1542 99.2287 15.0665 99.1255 16.8276L99.1017 17.2242C98.9985 19.017 99.6334 19.961 100.816 20.0324C102.03 20.1117 102.673 19.2153 102.776 17.4225Z"/>
<path d="M109.625 18.6996C109.767 17.9381 110.49 17.4145 111.283 17.5652C112.093 17.7159 112.561 18.4616 112.418 19.2232C112.275 20.0006 111.569 20.5083 110.759 20.3575C109.966 20.2068 109.482 19.4691 109.625 18.6996Z"/>


            </svg>
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




